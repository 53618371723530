body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  background-color: #4ecdc4 !important;
  border: 1px solid #4ecdc4 !important;
}

.text-right {
  text-align: right;
}

/* FONTS */
.ft-18 {
  font-size: 18px;
}

.ft-14 {
  font-size: 14px !important;
}

.ft-12 {
  font-size: 12px !important;
}

/* BUTTON */
.justify-content-space {
  justify-content: space-between !important;
}

.disp-flex {
  display: flex;
  align-items: center;
}


/* PADDING */
.header {
  display: flex;
  align-items: center;
  border-bottom: 3px solid #4ecdc4;
  margin: 0 0 20px;
  padding: 0 0 11px;
}

.page-logo {
  margin: 0 18px 0 0;
}

.page-logo {
  width: 100px;
}

.header img {
  width: 100%;
}

.sufix {
  font-size: 11px;
}

.required {
  color: #f00000;
}

.optional {
  color: #a8a8a8;
}




/*Employee View */
.attendance-header .page-logo {
    width: 80px;
}

.calendar-view .month-view{
  background: #4ecdc4;
  border-radius: 8px;
}

.calendar-view .btn-ctrl {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}